<template>
  <div id="news">
    <section class="bg-gradient-bottom bg-xl-gradient">
      <div class="container jumbotron position-relative">
        <picture>
          <source
            class="news-chat"
            media="(min-width:1200px)"
            srcset="https://static.fasney.me/sff/fasney/image/news/fasney-news-chat.png"
            alt="fasney 最新消息"
          >
          <img
            class="news-chat"
            src="https://static.fasney.me/sff/fasney/image/news/news-chat-mobile.png"
            alt="fasney 最新消息"
          >
        </picture>
        <div class="news-title">
          <p class="font-small-24 font-weight-bold mb-1">
            最新消息
          </p>
          <h2>NEWS</h2>
          <p class="mb-0 font-small-18 font-xl-24">
            與你分享
          </p>
          <p class="font-small-18 font-xl-24">
            最新最優惠的訊息
          </p>
        </div>
        <picture>
          <source
            class="news-girl"
            media="(min-width:1200px)"
            srcset="https://static.fasney.me/sff/fasney/image/news/fasney-news-girl.png"
            alt="fasney 最新消息 後支付"
          >
          <img
            class="news-girl"
            src="https://static.fasney.me/sff/fasney/image/news/fasney-news-girl-mobile.png"
            alt="fasney 最新消息 後支付"
          >
        </picture>
      </div>
    </section>
    <section class="news-card-group container">
      <DecEvent
        id="dec-event"
        class="col-md-6 col-xl-4"
      />
      <NormalEvent
        id="normal-event"
        class="col-md-6 col-xl-4"
      />
      <FaPoints class="col-md-6 col-xl-4" />
      <NewUpdateCard class="col-md-6 col-xl-4" />
      <UpdateCard class="col-md-6 col-xl-4" />
      <!-- <Card class="col-md-6 col-xl-4" /> -->
      <!-- <MarketCard class="col-md-6 col-xl-4" /> -->
    </section>
  </div>
</template>

<script>
import DecEvent from '@/components/news/decEvent/Card'
import NormalEvent from '@/components/news/normalEvent/Card'
import FaPoints from '@/components/products/fapoints/Card'
import NewUpdateCard from '@/components/news/newUpdate/Card'
import UpdateCard from '@/components/news/update/Card'
// import Card from '@/components/news/postpay/Card'
// import MarketCard from '@/components/news/market/Card'

export default {
  name: 'AllNewsCard',
  components: {
    DecEvent,
    NormalEvent,
    FaPoints,
    NewUpdateCard,
    UpdateCard
    // Card,
    // MarketCard,
  },
  mounted() {
    const decNowDate = new Date().getTime()
    const decStartDate = new Date(2023, 11, 18, 0, 0, 0).getTime()
    const decEndDate = new Date(2024, 0, 14, 23, 59, 59).getTime()
    if (!(decNowDate >= decStartDate && decNowDate <= decEndDate)) {
      var decEvent = document.getElementById('dec-event')
      decEvent.style.display = 'none'
    }

    const normalNowDate = new Date().getTime()
    const normalStartDate = new Date(2023, 4, 29, 0, 0, 0).getTime()
    const normalEndDate = new Date(2023, 11, 31, 23, 59, 59).getTime()
    if (!(normalNowDate >= normalStartDate && normalNowDate <= normalEndDate)) {
      var normalEvent = document.getElementById('normal-event')
      normalEvent.style.display = 'none'
    }
  }
}
</script>
