<template>
  <div>
    <slot name="header" />
    <div class="cooperate-item">
      <div class="cooperate-content">
        <slot name="content" />
      </div>
      <div class="cooperate-footer">
        <a
          rel="noreferrer noopener"
          target="_blank"
          class="text-black font-small-14"
          :href="btnLink"
        >{{ footerLink }}</a>
        <a
          rel="noreferrer noopener"
          target="_blank"
          :href="btnLink"
          class="btn cooperate-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CooperateItemVue',
  props: {
    btnLink: {
      type: String,
      default: ''
    },
    footerLink: {
      type: String,
      default: ''
    }
  }
}
</script>
