<template>
  <div id="news-fasney">
    <section class="news-gradient">
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-header-pc.jpg"
        alt="news-store"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-header-m.jpg"
        alt="news-store"
      >
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-content-pc.jpg"
        alt="news-store"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-content-m.jpg"
        alt="news-store"
      >
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-footer-pc.jpg"
        alt="news-store"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/news/discount/normal-footer-m.jpg"
        alt="news-store"
      >
    </section>
  </div>
</template>

<script>
export default {
  name: 'NewsEvent'
}
</script>
