<template>
  <section class="news-post">
    <img
      src="https://static.fasney.me/sff/fasney/image/news/news-postpay.jpg"
      class="post-news-img d-md-none"
      alt="fasney後支付合作商店"
    >
    <article class="container news-article no-gutters">
      <div class="col-xl-9 mt-xl-1 d-md-block d-none">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/news-postpay.jpg"
          class="post-news-img "
          alt="fasney後支付合作商店"
        >
      </div>
      <div class="col-xl-9 text-left">
        <div class="article-post-time">
          <img
            src="https://static.fasney.me/sff/fasney/image/news/time.svg"
            alt="time"
          >
          <small class="text-time">01 Sep 2020</small>
        </div>
        <h2 class="article-post-title">
          fasney後支付合作商店陸續上線
        </h2>
        <p class="article-post-text">
          週週上新品，購物免出門、免提袋！
        </p>
        <div class="cooperate-store">
          <p class="mb-2">
            使用方式
          </p>
          <ol>
            <li class="py-2">
              1: 下載 fasney App加入會員、取得消費額度，再到合作店家使用 fasney
              後支付。
            </li>
            <li class="py-2">
              2: 或在合作店家結帳的付款方式選
              「fasney後支付」，依畫面提示操作即可。
            </li>
          </ol>
          <p class="mb-2">
            合作店商
          </p>
          <div class="cooperate-item-group no-gutters news-outline-border">
            <div
              v-for="(item, index) in brand"
              :key="index"
              class="col-md-6 col-xl-4 item-border-right"
            >
              <CooperateItem
                :btn-link="item.btnLink"
                :footer-link="item.footerText"
              >
                <template v-slot:header>
                  <a
                    rel="noreferrer noopener"
                    :href="item.btnLink"
                    target="_blank"
                    class="d-block"
                  >
                    <img
                      class="img-fluid"
                      :src="
                        `https://static.fasney.me/sff/fasney/image/news/brand/${item.src}`
                      "
                      :alt="item.alt"
                    >
                  </a>
                </template>
                <template v-slot:content>
                  <p v-html="item.text" />
                </template>
              </CooperateItem>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CooperateItem from '@/components/news/postpay/CooperateItem'
import brand from '@/assets/json/brand'
export default {
  name: 'PostpayNews',
  components: {
    CooperateItem
  },
  data() {
    return {
      brand
    }
  }
}
</script>
