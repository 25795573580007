<template>
  <section class="news-post">
    <img
      src="https://static.fasney.me/sff/fasney/image/news/market/market.jpg?v=0159"
      class="post-news-img mt-xl-1"
      alt="fasney後支付合作商店"
    >
    <article class="container news-article no-gutters">
      <div class="col-xl-9 text-left">
        <div class="article-post-time">
          <img
            src="https://static.fasney.me/sff/fasney/image/news/time.svg"
            alt="time"
          >
          <small class="text-time">10 May 2021</small>
        </div>
        <h2 class="article-post-title">
          PAZZO & MEIER.Q 滿千送百
        </h2>
        <p class="article-post-text">
          衣櫃補貨季 | fasney新用戶滿千回饋100元
        </p>
        <div class="cooperate-store">
          <p class="mb-2">
            [活動內容]
          </p>
          <ol>
            <li class="py-2">
              2021/05/10 ~ 5/31「新用戶」至「指定服飾品牌」使用fasney結帳：
            </li>
            <li class="py-2">
              消費滿千元回饋100元帳單折抵金(活動期間每人限回饋乙次)
            </li>
          </ol>
          <p class="mb-3">
            [指定通路]
          </p>
          <div class="cooperate-item-group no-gutters news-outline-border">
            <div
              v-for="(item, index) in brand"
              :key="index"
              class="col-md-6 col-xl-4 item-border-right"
            >
              <CooperateItem
                :btn-link="item.btnLink"
                :footer-link="item.footerText"
              >
                <template v-slot:header>
                  <a
                    rel="noreferrer noopener"
                    :href="item.btnLink"
                    target="_blank"
                    class="d-block"
                  >
                    <img
                      class="img-fluid"
                      :src="
                        `https://static.fasney.me/sff/fasney/image/news/brand/${item.src}`
                      "
                      :alt="item.alt"
                    >
                  </a>
                </template>
                <template v-slot:content>
                  <p>{{ item.text }}</p>
                </template>
              </CooperateItem>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <p class="mb-2">
            [活動辦法]
          </p>
          <ul>
            <li class="py-2">
              1.折抵金預計於【2021/7月份】帳單上發放。
            </li>
            <li class="py-2">
              2.新用戶定義為活動期間內申請fasney並經審核通過獲得消費信用額度者。
            </li>
            <li class="py-2">
              3.平台的「訂單成立日」須在活動期間內並且付款成功。
            </li>
            <li class="py-2">
              4.訂單累計金額以實際使用fasney支付之淨額計算。
            </li>
            <li class="py-2">
              5.fasney用戶於計算回饋時須為額度可正常使用之會員，如有當期及上一期帳單延滯繳款或違反fasney用戶服務條款等情事時，將喪失活動回饋資格。
            </li>
            <li class="py-2">
              6.折抵金僅限抵扣帳單應繳金額，恕不得要求折現或以溢繳款方式領回。若事後因辦理交易取消、退貨時，導致未符合活動門檻時，折扣金將於下期帳單扣回。
            </li>
          </ul>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CooperateItem from '@/components/news/postpay/CooperateItem'
import brand from '@/assets/json/market-brand'
export default {
  name: 'PostpayNews',
  components: {
    CooperateItem
  },
  data() {
    return {
      brand
    }
  }
}
</script>
