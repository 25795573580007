<template>
  <div id="update-news">
    <section class="news-gradient">
      <div class="container jumbotron position-relative mb-0">
        <img
          class="news-girl"
          src="https://static.fasney.me/sff/fasney/image/news/update/update-walk.png"
          alt="fasney update"
        >
        <img
          class="news-chat"
          src="https://static.fasney.me/sff/fasney/image/company/about-people.png"
          alt="關於 fasney"
        >
        <div class="news-title">
          <p class="update-new-text">
            重要更新
          </p>
          <h2 class="update-new-en">
            Update
          </h2>
        </div>
      </div>
    </section>
    <section class="container new-bill">
      <div class="row">
        <div class="col-xl-6 order-xl-2">
          <div class="d-xl-none mb-4">
            <h3 class="new-bill-title">
              新版帳單
            </h3>
            <span class="new-bill-date">(2021/04/19日起生效)</span>
          </div>
          <img
            class="img-fluid"
            src="https://static.fasney.me/sff/fasney/image/news/update/news-app.jpg?v=0416"
            alt=""
          >
        </div>
        <div class="col-xl-6">
          <div class="d-none d-xl-block">
            <h3 class="new-bill-title">
              新版帳單
            </h3>
            <span class="new-bill-date">(2021/04/19日起生效)</span>
          </div>
          <ul class="update-list">
            <li>
              <span class="list-circle">1</span>
              <strong class="list-title">簡潔清晰的帳單資訊</strong>
            </li>
            <li>
              <span class="list-circle">2</span>
              <strong class="list-title">免手動轉慢慢還</strong>
              <div class="list-content">
                <p>
                  帳單直接顯示「應繳總額」及「最低應繳金額」，化繁為簡免再按「慢慢還」。
                </p>
                <p>
                  若有理財規劃而不想全額繳清，只要按時繳足最低金額就不會有違約金，而當期剩餘未償金額將從繳款截止日開始計算利息。利息以日利率0.043%計算
                </p>
              </div>
            </li>
            <li>
              <span class="list-circle">3</span>
              <strong class="list-title">即時計算待繳金額</strong>
              <div class="list-content">
                <p>
                  繳款入帳、當期帳單或以前的消費退貨…等情況，都會幫您重算當期「待繳金額」，很輕鬆哦。
                </p>
                <p class="mb-0">
                  超商繳款需2~3個工作天才有紀錄、店家退貨則視店家處理流程及速度而有所不同
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="terms-container">
      <div class="container">
        <h3 class="terms-title">
          用戶服務條款更新
        </h3>
        <div class="terms-service-content">
          <p>用戶服務條款</p>
          <p>
            fasney(以下稱本平台)係由美而快電商金融科技股份有限公司(以下稱本公司)所經營，本平台提供用戶使用後支付、慢慢還、好好貸等服務，並不定期推出其他優質服務供用戶使用。
          </p>
          <ul>
            <li>第壹條 名詞定義</li>
            <li>
              <p>一、應收帳款債權：</p>
              <p>
                基於買賣契約、勞務契約或其他債權契約所得向您請求支付價款之權利及所生之其他一切權利及利益。
              </p>
            </li>
            <li>
              <p>二、應付帳款：</p>
              <p>
                如無其他特別約定時，係指您當期及前期累計未繳之全部款項，明細項目依各期帳單所載。
              </p>
            </li>
            <li>
              <p>
                三、結帳日、繳款截止日
              </p>
              <p>
                係指本公司按第肆條所設定之帳款結算日，於結帳日次日寄出繳款通知單(帳單)；繳款通知單上所載之最後繳款日期即為繳款截止日。
              </p>
            </li>
            <li>
              <p>
                四、入帳日：
              </p>
              <p>
                係指本公司受讓應收帳款債權，對讓與方負有給付約定價金責任之日，亦為登錄於您帳上之日。
              </p>
            </li>
            <li>
              <p>
                五、電子文件：
              </p>
              <p>
                指文字、聲音、圖片、影像、符號或其他資料，以電子或其他以人之知覺無法直接認識之方式，所製成足以表示其用意之紀錄，而供電子處理之用者。
              </p>
            </li>
            <li>
              <p>
                六、生物特徵辨識：
              </p>
              <p>
                指您利用行動裝置內建之指紋/臉部/虹膜/聲音/掌紋/靜脈等生物特徵之辨識功能，驗證您的生物特徵，並於本平台上讀取驗證結果，以確認您的身分及進行交易指示。
              </p>
            </li>
          </ul>
          <ul>
            <li>第貳條 帳號申請及個資保護</li>
            <li>
              一、本平台以「fasney
              App」為主要介面，因服務包含及授信及帳務管理等業務，您須註冊並通過審核後始能使用對應之服務。本公司有權自行或委託合作機構進行您的身分驗證、徵信及審核，亦得依實際情況蒐集您的徵信資料或財力證明或合作店家之交易紀錄等，本公司有全權決定是否同意您的申請，並得不揭露其審核程序且無須返還本次申請資料及所附文件。
            </li>
            <li>
              二、本平台為強化安全考量，您僅得註冊一個帳號，不得重複註冊；本平台將於申請流程中登錄您的人臉特徵值並關聯您的帳號(手機號碼、身分證字號)，並得因業務需要，隨時請求更新人臉特徵值，如您不同意留存人臉特徵值，則請勿申請本服務。
            </li>
            <li>
              三、您保證提供予本平台之所有資料，包括但不限於個人資料、身分證件…等個人文件，均為您本人所有且為即時、正確、完整，並無重複、不實、偽造、變造、刻意冒用他人名義之情事，亦無侵害他人之姓名權、公司名稱、商標或有其他違反法令，本公司有權自行判斷後，隨時逕行拒絕你的註冊及申請、取消您的會員帳號、調整額度、停止或取消交易，並得暫停或終止您使用本服務之資格與權利。
            </li>
            <li>
              四、您了解並同意，合作店家及本公司已依據個人資料保護法相關規定告知下列事項：
              <p>
                (1)合作店家及本公司為分期付款買賣、融資性租賃及應收帳款受讓等相關業務或其他合於營業登記項目或組織章程所定之業務，得蒐集、處理及利用您之識別類與特徵類個人資料；
              </p>
              <p>
                (2)利用期間，為蒐集之特定目的存續期間或合作店家及本公司因執行業務所必須之保存期間；
              </p>
              <p>
                (3)利用對象，為合作店家、本公司或與本公司有業務往來之機構(含本公司及本公司之關係企業及其日後成立之子公司或關係企業、勞工保險局或其他徵信機構(包括但不限於中華徵信所)、本公司之共同行銷或交互運用客戶之機構、本公司合作推廣對象、與本公司有業務往來之機構、同意為您清償應付帳款之第三人、聯絡人(基於本公司帳款管理之目的)、依法有調查權機關或金融監理機關；
              </p>
              <p>
                (4) 利用地區，為利用對象之國內外營業所在地；
              </p>
              <p>
                (5)
                利用方式，為符合個人資料保護法以自動化機器或其他非自動化方式之利用方式(如電子文件或紙本)，或符合個人資料保護法第20條規定之利用方式。同時您得依個資法相關規定向本公司查詢、請求閱覽或請求製給複製本及行使權利，而本公司得酌收必要成本費用。您並得自由選擇是否提供個人資料，若拒絕提供時，合作店家及本公司將無法提供完善之業務或服務。您並同意本公司得代理本人並使用本人之個人資料暨銀行資料向發卡銀行及電信公司等為相關查詢。其餘關於本平台對於個人資料之蒐集、處理及利用，悉依本平台之隱私權保護政策。
              </p>
            </li>
          </ul>
          <ul>
            <li>第參條 帳號安全</li>
            <li>
              一、凡以您的帳號及密碼進入本平台或使用本服務之所有行為，包括登入、查詢、閱覽、更改個人資料、經生物特徵辨識驗證通過所發出之指示、參與各項交易或活動暨取得相關資訊，以及所有涉及會員權益之相關或類似行為等，均視為您本人之行為。
            </li>
            <li>
              二、您於本平台之會員帳號不可轉讓，且您應妥善保管您於本平台設定或使用之裝置、帳號、密碼及其他相關資料等並負保管及保密義務，以維護其安全及您自身權益，不得洩露或提供給第三人知悉、給第三人使用或與第三人共用，亦不可使用其他任何非您本人之帳號、密碼等，您亦不得利用本服務之帳號及會員資格從事任何違法或侵害他人權益之行為。您並同意當發現您的帳號、密碼等資料被非法使用或發現本平台之安全漏洞時，應立刻通知本公司。如果您因為違反本條約定而造成任何您自身或第三人之傷害或損失，將由您自行負責處理，概與本公司無涉。
            </li>
            <li>
              三、您在本平台所進行之各樣行為，均由系統即時接收並依相關服務指令即時或批次處理，除本平台明確提供撤回、取消或修改該指令之功能外，您一經確認指令後，即不得撤回、取消或修改已發送至本服務系統之服務指令。若您發現資訊有誤，應立即通知本平台，經本平台確認後更正之。如有任何爭議，您亦同意皆以本平台紀錄之資料為準。
            </li>
            <li>
              四、本平台僅供您進行合作店家查詢、優惠訊息、買賣申請及交易、帳款查詢及繳交等事項及功能使用，就交易額度、帳款查詢之結果，您應自負保密責任，並不得任意複製、拷貝，或洩漏予任何第三人，以維護您自身權益。
            </li>
          </ul>
          <ul>
            <li>
              第肆條 帳號及額度管理
            </li>
            <li>
              一、本平台依第貳條進行審核並設定往來額度、每月固定結帳日、繳款截止日；此額度係為加速評估是否受讓合作店家對您的應收帳款債權或分期付款買賣所用，得視您或合作店家之狀況，設定每筆、每日或一定期間交易金額及次數上限，本公司並未保證受讓或承作，您仍對各筆契約負清償責任；此額度亦非永久有效，本平台得隨時視狀況(包括但不限於再次檢視或查詢您的信用狀況、經濟來源、償還能力、交易紀錄、還款紀錄、高風險之關聯戶)逕行調整或終止額度；本公司亦有權逕行依業務需求或主管機關之要求調整或變更使用流程及規則。
            </li>
            <li>
              二、若您經本平台判斷或經本平台之合作店家、配合廠商通知您有任意退換貨、高頻率取消訂單、異常交易、洗錢嫌疑等或任何本平台認為不合理或不適宜使用之情事，本平台得視情況拒絕交易、關閉帳號，暫停或永久禁止使用者使用本平台服務。
            </li>
            <li>
              三、若您的個人資料在事後有變更或已知有誤，應立即至本平台更新之，不得以資料不符或錯誤為由，否認交易行為或拒絕付款，如有任何虛假或冒用他人名義登錄者，或因未立即更新所衍生之損害或不利益，將由您自行負責。
            </li>
            <li>
              四、本平台如有業務上有關文書或應為之通知，將依您最後留存之聯絡地址為送達之處所，並於發出後經通常郵遞之期間，即推定已合法送達。
            </li>
          </ul>
          <ul>
            <li>
              第伍條 交易(買賣)申請及售後
            </li>
            <li>
              一、您(即申請人，亦為買方)同意以分期付款之方式，向本服務之合作店家(即原債權人，亦為賣方)購買於本平台之交易確認頁面中所載之商品或服務(下稱本標的)，於您在本平台點選交易確認時已充分知悉並同意，本公司(即應收帳款債權之受讓人)對於交易申請保留核准與否之權利，對於分期買賣契約是否生效亦保有最終同意權。
            </li>
            <li>
              二、交易申請經本公司審核通過後，合作店家即已將應收帳款債權及其所生之其他一切權利及利益讓與本公司及其受讓人；本公司於扣除手續費及相關費用後，一次付款予合作店家，以為收買該應收帳款債權；合作店家同時授權本公司及其受讓人管理帳務，不另為書面通知。
            </li>
            <li>
              三、本標的如為實體商品時，您同意依本分期付款買賣契約承買，並僅得先行佔有及使用本標的，於本分期價款及分期付款買賣契約之約定未全部履行清償前，合作店家仍保有所有權，您僅得先行依善良管理人義務保管、占有使用，不得擅自處分(包含但不限於出售、移轉、質押、典當等)本標的，於您未依約繳付或違約時，本公司得要求您交付本標的。
            </li>
            <li>
              四、本標的之總價款、頭期款(訂金)、分期付款金額、分期期數、期付款、利率等交易資訊，均依本平台之交易確認頁面所載。另有關本標的之商品規格、品質、數量等，悉依合作店家所提供之品質保證書或出貨憑證所載。
            </li>
            <li>
              五、您知悉本公司非本標的商品之進口人、出售人或經銷人，亦非服務提供人，與合作店家間無代理、經銷、合夥等類似關係，並且有關本標的之買賣或勞務等瑕疵擔保，以及贈品、保固、保險、保證、售後服務與其他法律上及契約上所生糾紛，應由合作店家即賣方或提供勞務者承擔，您不得以上述瑕疵或事由對抗本公司或其受讓人而拒絕繳款。
            </li>
            <li>
              六、如為訪問交易或通訊交易之消費，且非消費者保護法第19條第2項規定之情形時，您得自領受商品後七日內無需理由，以信函通知或逕行將商品退回合作店家，以為解除契約之意思表示；故您於領受商品時應即驗收，並於發現商品有瑕疵時應即通知合作店家，您怠為瑕疵通知者，除依通常檢查不能發現之商品瑕疵外，視為承認所受領之物，標的物之危險自您占有標的物時起，由您自行承擔。
            </li>
          </ul>
          <ul>
            <li>
              第陸條 帳單及帳款疑義之處理
            </li>
            <li>
              一、您的應付帳款如於當期結帳日前發生變動或尚未清償，除您已逾期繳款進入催收程序將依本公司催收方式辦理外，本平台按約定將於結帳日次日產出電子帳單，您可於本平台上查詢近期帳單(含當期)、還款紀錄及交易明細。倘您要求本平台提供超過3個帳款期間以前之帳單或交易明細，或要求以非約定之方式印製或寄送帳單，本平台得按每帳款期間(月)收取新台幣100元之補發帳單手續費。
            </li>
            <li>
              二、您於當期繳款截止日前，如對帳單所載之交易明細有疑義，得檢具理由及平台要求之證明文件(如訂購單、發票、收據、交易歷程截圖等)，通知本平台協助處理；或同意負擔新台幣50元之調單手續費後，請求本平台向店家調閱相關資料。若您未依約定通知本平台者，推定帳單所載事項無錯誤。
            </li>
            <li>
              三、您於消費爭議情事發生時，除已向消費者保護機構或司法機關聲請調解或提起訴訟，並取得可止付款項之相關證明者外，不得逕行止付款項；如您未為繳付時，其後如經證明非屬消費爭議或因非可歸責於合作店家或本公司之事由，致衍生爭議者，您於受合作店家或本公司通知後，應立即繳付，並自應繳期日之次日起，以日息0.043%計付利息予本公司。
            </li>
          </ul>
          <ul>
            <li>
              第柒條 繳款
            </li>
            <li>
              一、您於當期繳款截止日前，應照本平台提供之繳款方式，繳付當期帳單所載之應付帳款或最低應繳金額以上之帳款，並自行負擔各通路之繳款手續費。
            </li>
            <li>
              二、您每期最低應繳金額包含：
              <p>
                (1)利息、違約金、手續費用(包括但不限於調單、清償證明、補發帳單、匯款、設定分期)、催收及法律程序費用等；
              </p>
              <p>(2) 定額分期消費之當期期付金全額；</p>
              <p>(3)當期新增一般消費款項之百分之十；</p>
              <p>
                (4)前期未清償一般消費及不定額分期消費等剩餘未繳足應付帳款之百分之五；
              </p>
              <p>(5) 累計當期以前各期逾期未付最低應繳款項之總和。</p>
              <p>
                以上總和如低於新台幣800元，以新台幣800元計。
              </p>
            </li>
            <li>
              三、您應依第一項約定繳款，您就剩餘未付款項得延後付款，且得隨時清償原延後付款金額之全部或一部分。如您有延遲付款(不含已繳足最低應繳金額者)、違反約定事項、退票、信用貶落、銀行拒往、受假執行、假扣押、破產聲請、宣告、死亡等情事時，所有未到期價款視為提前全部到期，本公司得不經催告，逕行要求您立即清償全部債務，並另支付自遲延繳款日或違約日起至清償日止，依年利率百分之十六約定利率計收遲延利息，及每期(月)計收300元之違約金。
            </li>
            <li>
              四、分期交易於特約商店請款後，若發生退貨或部份退貨之情形時，扣除退貨金額仍有應繳款項，則系統自動依原始交易時設定之期數重新計算分期款項。
            </li>
            <li>
              五、使用定額分期消費，視您同意依交易時所設定之期數，每月繳付分期款項，若有提前結清分期款項之情形，本公司得收取提前清償解約費，每筆消費計收100元，於操作分期提前結清繳款時一併收取。
            </li>
            <li>
              六、已付款項若無另外約定 ，應依序抵沖帳款
              中之費用、利息、違約金、本金，其中本金部分係優先抵沖全額納入最低應繳之款項
              (如遇定額分期消費之期付金無法足額抵沖則略過該期金，繼續往下)，次抵沖前期剩餘未付本金款項，再抵沖新增當期帳款之本金
              ；就抵沖後之應付帳款餘額，自繳款截止日當日起，以您適用之利率(顯示本平台會員中心，最高利率為民法第205條規定之約定利率上限)計付利息。
            </li>
            <li>
              七、如您有溢繳應付帳款之情形，您同意由本公司無息保管並得以抵付後續須付給本公司之帳款；如往後連續兩期皆無帳款可抵沖，本平台應主動通知您依約定之方式申請退回溢繳款項；惟若您主動要求將款項退回，須給付每次新台幣100元之作業處理費用，並直接自退回之款項中扣除。
            </li>
            <li>
              八、當發生退貨等情事致合作店家須買回應收帳款，則本平台將於受理店家通知當日，以前項約定之順序抵沖。您不可主張辦理退貨及合作店家買回債權之作業期間應退回已付或抵減應付之利息。
            </li>
          </ul>
          <ul>
            <li>第捌條 認知與接受</li>
            <li>
              一、本公司有權隨時修訂或變更本服務條款，修訂後之服務條款於本平台刊出後立即生效，將不另作個別通知；您應隨時注意本平台所公布之訊息，倘您於修訂或變更之服務條款刊出後仍繼續使用本平台，即視為您已閱讀、瞭解並同意接受該等修改或變更；若不同意上述的修訂或變更，或不接受或不符合本服務條款的其他任一約定，您應立即停止使用本平台。若您尚未成年或無完全行為能力，請於您的法定代理人(家長)或監護人閱讀、瞭解並同意本條款及其後修改、變更後之所有內容後，方得使用本平台。
            </li>
            <li>
              二、您同意以電子文件作為表示方式，依本服務條款交換之電子文件，其效力與書面文件相同。
            </li>
            <li>
              三、本平台所提供之服務及行銷活動通常有各自之服務條款及注意事項(以下稱特別條款)，您應詳閱特別條款後始進行各項服務，該特別條款將優先適用於本服務條款，若有未提及之內容，則由本服務條款補充之。
            </li>
          </ul>
          <ul>
            <li>第玖條 服務可用性</li>
            <li>
              一、本公司以專業服務維持本平台服務可用性及保密性，惟本公司不保證本平台與您的行動裝置完全相容；亦不保證本平台沒有錯誤或缺陷，且本平台可能因設備、系統、網路、電力等障礙或故障、系統維護或修復、不可抗力等因素而中斷、暫停或停止服務，故本公司不擔保本平台及本服務之穩定、安全、可用、無誤及不中斷，對於服務中斷、暫停或停止而造成您或第三人的使用不便或損害(包括但不限於增加交易時間、喪失交易資格或預期利益等直接或間接損害)，本公司均不負擔任何責任。
            </li>
            <li>
              二、本平台因作業或業務需求，將保留隨時新增或變更或終止各項服務內容，且無需事先通知您；就停止或更改服務或終止您服務之決定，本平台對您或任何第三人均不負任何責任。
            </li>
          </ul>
          <ul>
            <li>
              第壹拾條 權利聲明
            </li>
            <li>
              一、本平台所使用之文字、圖片、影像、軟體、網頁設計及程式等所有內容及資料之智慧財產權(包括但不限於商標權、專利權、著作權、營業秘密與專有技術等)均由本公司依法擁有或取得授權。任何人未經本公司及權利人授權，不得以任何方式擅自使用、重製、改作、編輯、公開傳輸、公開展示、公開播送、公開發表、散佈、發行、或進行還原工程解編或反向組繹，亦不得以人為或機器蒐集或利用本平台刊載之相關資料或數據。
            </li>
            <li>
              二、合作店家或廣告主或贊助廠商在本平台填寫或提供之啊商品資訊、分期價款資訊、個人資料、上傳圖片、廣告內容等行為，均由其自行負責；用戶對於內容之真實性、完整性及正確性應自行斟酌與判斷，本平台對其內容之真實性、完整性及正確性不負任何責任。
            </li>
          </ul>
          <ul>
            <li>
              第壹拾壹條 承諾與違反責任
            </li>
            <li>
              一、當您於註冊、申請、使用本平台/本服務或進行交易時，您應遵守中華民國法令、網際網路規定與國際慣例、本平台之資訊、流程、交易之契約以及本服務條款。
            </li>
            <li>
              二、如您有影響、干擾或妨礙本平台之正常運作、帳號申請、交易進行、繳款、系統或流程等行為，或有任何不符或違反法令或本服務條款或有違反之虞者，本公司有權自行判斷後，隨時逕行拒絕你的註冊及申請、取消您的會員帳號、停止或取消交易，並得限制、暫停或終止您使用本服務之額度、資格與權利，且得逕行向司法機關舉報之。您並應自負一切民事、刑事與行政之法律責任，如因此產生任何不利益或損害，亦由您自行負責，如造成本公司或任何第三人損害或支出費用（包括且不限於因進行民事、刑事及行政程序所支出之律師費用），您並應負賠償責任或填補其費用。
            </li>
            <li>
              三、如您的會員帳號資格有受限制、暫停或終止之情形，包括但不限於您停止使用本平台、您的會員資格被取消、限制或終止、或本平台停止服務，在此之前所發生之權利義務(如:本公司對您之應收帳款債權)，均不因此受影響。
            </li>
          </ul>
          <ul>
            <li>
              第壹拾貳條 管轄法院及準據法
            </li>
            <li>
              一、您與本公司共同約定，如有爭議發生時，雙方需先本於誠信協商解決，協商不成者而有訴訟必要時，您與本公司同意以臺灣臺北地方法院為第一審管轄法院，並以中華民國法律為準據法。
            </li>
            <li>
              <p>二、本公司資訊如下：</p>
              <p>
                (1) 名稱：美而快電商金融科技股份有限公司
              </p>
              <p>
                (2) 統編：50761362
              </p>
              <p>
                (3) 負責人：廖承豪
              </p>
              <p>
                (4) 地址：台北市南港區南港路三段47巷8號
              </p>
              <p>
                (5) 客服信箱：service@urfintech.com.tw
              </p>
              <p>
                (6) 服務時間：周一至週五 09:00~17:30
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UpdateNews'
}
</script>
